export enum Variant {
  DEFAULT = 'default',
  FILLED = 'filled'
}
export type variantTypes = `${Variant}`;
export type statusTypes = `${Status}`;

export enum Status {
  NORMAL = 'normal',
  SUCCESS = 'success',
  ERROR = 'error',
  FOCUS = 'focus'
}
