import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import PropertyControlledComponent from '../PropertyControlledComponent';

import styles from './Accordian.module.scss';

interface Props {
  title: string;
  layoutType?: 'flex' | 'grid';
  settingsItemClass?: string;
  accordionContainerClassName?: string;
  accordionDetailsClassName?: string;
  subtitle?: string;
  columns?: number;
}
export default function CustomAccordion(props: PropsWithChildren<Props>) {
  const {
    title,
    children,
    subtitle,
    layoutType,
    settingsItemClass = '',
    accordionContainerClassName = '',
    accordionDetailsClassName = '',
    columns = 2
  } = props;
  return (
    <div
      className={`relative  left-[50px] ml-0 w-[90%] justify-center rounded-[0.25rem]  ${styles.accordionContainer} ${accordionContainerClassName}`}
    >
      <Accordion className={`flex flex-col ${styles.accordion}`}>
        <AccordionSummary
          style={{ backgroundColor: '#313132' }}
          className={styles.accordianSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <span
            className={`font-inter flex w-full justify-start !text-lg !text-white ${styles.subheading}`}
          >
            {title.toLocaleUpperCase()}
          </span>
          <PropertyControlledComponent controllerProperty={subtitle}>
            <span className={`font-inter !text-md flex w-full justify-start !text-white `}>
              {subtitle}
            </span>
          </PropertyControlledComponent>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: '#313132' }}>
          <div
            className={` w-full ${styles.detailsContainer} !grid w-full !${layoutType} gap-y-8 !text-[#f8f6f6] ${accordionDetailsClassName}`}
            style={{
              gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`
            }}
          >
            {React.Children.map(children, (child) => {
              return (
                <div
                  className={`mt-3 flex w-full items-center  justify-between  text-[#46454E] ${styles.settingsItem} ${settingsItemClass}`}
                >
                  {child}
                </div>
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
