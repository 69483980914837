import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide, { SlideProps } from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import _map from 'lodash/map';
import React, { ReactElement, Ref } from 'react';

import styles from './ConfirmationDialog.module.scss';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<SlideProps>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface OptionProp {
  optionText: string;
  onSelect: () => void;
}
interface Props {
  dialog?: string;
  isButtonRequired?: boolean;
  dialogTitle: string;
  options: OptionProp[];
  handleButtonText: string;
  handleButtonClassname: string;
  closeButtonText: string;
}
export default function ConfirmationDialog(props: Props) {
  const {
    dialog,
    dialogTitle,
    options,
    handleButtonText,
    handleButtonClassname,
    closeButtonText,
    isButtonRequired
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {isButtonRequired ? (
        <Button variant="outlined" onClick={handleClickOpen} className={handleButtonClassname}>
          {handleButtonText}
        </Button>
      ) : (
        <div onClick={handleClickOpen}> {handleButtonText}</div>
      )}
      <Dialog
        open={open}
        className={styles.dialogModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ backgroundColor: '#444444' }} className=" font-bold text-white">
          {dialogTitle}
        </DialogTitle>
        <DialogContent style={{ backgroundColor: '#444444' }}>
          <DialogContentText id="alert-dialog-slide-description">{dialog}</DialogContentText>
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#444444' }}>
          {_map(options, (option) => (
            <Button
              onClick={() => {
                option.onSelect();
                handleClose();
              }}
              className={styles.modalOptions}
            >
              {option.optionText}
            </Button>
          ))}
          <Button onClick={handleClose} className={styles.modalOptions}>
            {closeButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
