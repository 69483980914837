import { Fade, IconButton, Pagination } from '@mui/material';
import React, { FC, useState } from 'react';

import Input from '../Input';

import styles from './Pagination.module.scss';

interface PaginationProps {
  isLoading: boolean;
  page: number;
  totalPages: number;
  className?: string;
  handleOnChange: (event: unknown, page: number) => void;
}

const PaginationComponent: FC<PaginationProps> = ({
  isLoading,
  page,
  totalPages,
  className = '',
  handleOnChange
}) => {
  const [paginationInputValue, setPaginationInputValue] = useState(page);

  const hasError = paginationInputValue < 1 || paginationInputValue > totalPages;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);

    if (Number.isInteger(newValue)) setPaginationInputValue(newValue);
  };

  const handlePagination = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (
      paginationInputValue > 0 &&
      paginationInputValue <= totalPages &&
      paginationInputValue !== page
    )
      handleOnChange(event, paginationInputValue);
  };

  if (totalPages < 1) return <div className={styles.paginationEmpty}>That's all folks!</div>;
  return (
    <Fade in={!isLoading} {...(!isLoading ? { timeout: 1000 } : {})}>
      <div className={`${styles.paginationWrapper} ${className}`}>
        <span className={styles.text}>Total No of Pages: {totalPages}</span>
        <div className={styles.textWrapper}>
          <Pagination
            page={page}
            count={totalPages}
            onChange={handleOnChange}
            classes={{ root: styles.navigation }}
          />
          <div className="flex flex-row items-center bg-[#191a1b]">
            <span className={`${styles.text} ml-4 mr-8`}>Go To</span>
            <Input
              value={paginationInputValue}
              className={`${styles.titleInput} h-[30px]`}
              overRideStyles={{
                color: 'white',
                height: '30px',
                backgroundColor: '#2e2e2e',
                border: '0.2px solid gray !important',
                borderRadius: '10px',
                width: '200px'
              }}
              type="tel"
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="close"
                    className={styles.inputSubmit}
                    onClick={handlePagination}
                    disabled={hasError}
                  >
                    ✔
                  </IconButton>
                ),
                disableUnderline: true
              }}
              onChange={handleInputChange}
              error={hasError}
            />
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default PaginationComponent;
