import { TextField } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { InputbackGround, TextColor } from './Input.constant';
import { Variant, variantTypes, statusTypes } from './Input.types';
import './input.module.scss';

const StyleInput = styled(TextField)<{
  textcolor?: string;
  inputwidth?: string;
  inputbackGround?: string;
  overridestyles?: React.CSSProperties; // Update the type here
}>(({ textcolor, inputwidth, inputbackGround, overridestyles }) => ({
  width: inputwidth,
  backgroundColor: inputbackGround || '#FFFFFF',
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '1rem',
  borderRadius: '4px',
  '&:hover': {
    borderColor: 'none'
  },
  '&:focus': {
    borderColor: textcolor
  },
  '& .MuiInputBase-root': {
    ...overridestyles
  },
  '& .MuiOutlinedInput-root': {
    border: `1px solid ${textcolor}`
  }
}));
interface InputProps {
  variant?: variantTypes;
  width?: string;
  status?: statusTypes;
  disable?: boolean;
  defaultValue?: string;
  overridestyles?: React.CSSProperties;
  [key: string]: unknown;
}
export default function Input({
  variant = 'default',
  width = '15.438rem',
  status = 'normal',
  disable = false,
  defaultValue = '',
  overRideStyles = {},
  ...props
}: InputProps) {
  const textColor = TextColor[status];
  const inputbackGround = InputbackGround[status];

  const inputField = {
    [Variant.DEFAULT]: (
      <StyleInput
        inputwidth={width}
        variant="outlined"
        textcolor={textColor}
        inputbackGround={inputbackGround}
        error={status === 'error'}
        disabled={disable}
        focused={status === 'focus'}
        margin={status === 'error' || status === 'success' ? 'normal' : 'none'}
        overridestyles={overRideStyles}
        {...props}
      />
    ),
    [Variant.FILLED]: (
      <StyleInput
        margin="normal"
        id={disable ? 'filled-disabled' : ''}
        inputwidth={width}
        variant="filled"
        disabled={disable}
        textcolor={disable ? '#6D6AF4' : '#BEC7CC'}
        inputbackGround={disable ? '#ffffff' : '#f3f8fb'}
        defaultValue={defaultValue}
        InputProps={{ disableUnderline: true }}
        overridestyles={overRideStyles}
        {...props}
      />
    )
  }[variant];
  return <div className="ganInputFieldContainer">{inputField}</div>;
}
