import { formatISO } from 'date-fns';
import _includes from 'lodash/includes';
import * as yup from 'yup';

export const dateFormat = (d: Date) =>
  d
    ? formatISO(d, {
        format: 'extended',
        representation: 'complete'
      })
    : null;

export const VALIDATON_MESSAGES = {
  REQUIRED: 'Name is a mandatory field',
  MIN_CHAR: 'Name should have atleast 3 characters',
  MAX_CHAR: 'Name should have at most 30 characters',
  MIN_ALPHABET: 'Name should contain atleast 1 alphabet',
  SPECIAL_CHAR: `Name can only contain: \n
  Lowercase characters: a-z \n
  Uppercase characters: A-Z \n
  Numbers: 0-9 \n
  Special characters: _ , - \n
  Spaces are allowed`,
  WHITESPACE: 'Name should not contain trailing or leading spaces',
  DUPLICATE: 'This name has already been used',
  RESERVE_WORDS:
    'recipient, email, mobile_number, thumbnail and replacement cannot be used as a name',
  FIRST_CHAR_NUMBER: 'Name should not begin with a number'
};

export const alphabetRegex = /[a-zA-Z]/;
export const specialCharRegex = /^[-_a-zA-Z0-9\s]*$/;
export const whiteSpaceCheckRegex = /^\s+|\s+$/;
export const firstCharNumberCheckRegex = /^\d/;

export const checkDuplicateVariableName = (variables: Array<string>, value: string) => {
  if (_includes(variables, value)) {
    return true;
  }
  return false;
};

export const getValidationSchema = (variables: Array<string>) => {
  const schema = yup
    .string()
    .required()
    .min(3, VALIDATON_MESSAGES.MIN_CHAR)
    .max(30, VALIDATON_MESSAGES.MAX_CHAR)
    .test('has-1-alphabet', VALIDATON_MESSAGES.MIN_ALPHABET, (value: string) =>
      alphabetRegex.test(value)
    )
    .test('contains-allowed-special-chars', VALIDATON_MESSAGES.SPECIAL_CHAR, (value) =>
      specialCharRegex.test(value)
    )
    .test(
      'first-char-number',
      VALIDATON_MESSAGES.FIRST_CHAR_NUMBER,
      (value) => !firstCharNumberCheckRegex.test(value)
    )
    .test(
      'contains-no-trailing-leading-spaces',
      VALIDATON_MESSAGES.WHITESPACE,
      (value) => !whiteSpaceCheckRegex.test(value)
    );
  return schema;
};
