import PropertyControlledComponent from '@libs/atoms/PropertyControlledComponent/PropertyControlledComponent';
import { Modal, Paper, Slide } from '@mui/material';
import _keys from 'lodash/keys';
import _map from 'lodash/map';
import _size from 'lodash/size';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/AppStore';

interface TableModalProps {
  open: boolean;
  onClose: () => void;
}

function TableModal(props: TableModalProps) {
  const { open, onClose } = props;
  const annotationProjectDetails = useSelector(
    (state: RootState) => state.project.annotationDetails
  );
  return (
    <Modal open={open} onClose={onClose} className="flex justify-end">
      <Slide direction="left" in={open} timeout={500}>
        <Paper elevation={3} className="rounded-lg !bg-[#191a1b]">
          <div className="text-white">
            <PropertyControlledComponent
              fallback={<div className="p-5">No data found!</div>}
              controllerProperty={_size(annotationProjectDetails)}
            >
              <div className="grid w-[900px] grid-cols-1 p-[20px] ">
                <table className="table-auto">
                  <thead>
                    <tr>
                      <th className="px-20">Project ID</th>
                      <th className="px-20">User Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_map(_keys(annotationProjectDetails), (key) => (
                      <tr>
                        <td className="px-20">{key}</td>
                        <td className="px-20">{annotationProjectDetails[key]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </PropertyControlledComponent>
          </div>
        </Paper>
      </Slide>
    </Modal>
  );
}

export default TableModal;
