import ConfirmationDialog from '@libs/atoms/ConfirmationDialog/ConfirmationDialog';
import { ConfirmationModal } from '@libs/atoms/ConfirmationModal';
import PropertyControlledComponent from '@libs/atoms/PropertyControlledComponent';
import { KeyboardBackspace, ListAlt, MoreVert, Refresh } from '@mui/icons-material';
import { IconButton, MenuItem, Popover, TextField, Tooltip } from '@mui/material';
import { ScreenFlowTypes } from 'components/Newproject/newproject.types';
import { useProject } from 'hooks/useProject';
import _get from 'lodash/get';
import moment from 'moment';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setProjectModal } from 'slices/ProjectSlice';
import { setStatus } from 'slices/StatusSlice';
import { PROJECT_STATUS, ProjectDetails } from 'types/Project';

import { useLazyGetAnnotationProjectDetailsQuery } from 'store/api/slices/getApis/getApisSlice';
import styles from './ProjectCard.module.scss';

enum MenuType {
  RENAME,
  DUPLICATE,
  DELETE,
  NONE
}

const ProjectCard = (props: { project: ProjectDetails; refetch: () => void }) => {
  const { project } = props;
  const [fetchProjectDetails, result, _] = useLazyGetAnnotationProjectDetailsQuery();
  const { annotation_project_id } = props.project;

  const history = useRouter();
  const titleRef = useRef<HTMLInputElement>(null);
  const [isMenuOpen, toggleMenu] = useState<HTMLElement | null>(null);
  const [selectedOption, setSelectedOption] = useState<MenuType>();
  const [currentProjectName, setProjectName] = useState<string>(project?.title);

  const { updateProjectName, projectDelete, projectDuplicate, fetchAnnnotationProjectDetails } =
    useProject();
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedOption === MenuType.RENAME) {
      titleRef.current?.focus();
    }
  }, [selectedOption]);

  useEffect(() => {
    if (project && project.title) {
      setProjectName(project.title);
    }
  }, [project]);

  const handleDeleteClick = async () => {
    const response = await projectDelete(annotation_project_id);
    if (response && response?.success) {
      toggleMenu(null);
    }
  };
  const handleDuplicateProject = async () => {
    const response = await projectDuplicate(annotation_project_id);
    if (response) {
      toggleMenu(null);
      dispatch(setStatus({ type: 'success', message: 'Successfully duplicated' }));
    }
  };
  const handleCardClick = () => {
    if (project.source_language_code && project.status === 'draft' && !project.asr_service) {
      ConfirmationModal.show({
        visible: true,
        title: 'Please Select an ASR Service',
        bodyElement: <></>,
        onSubmitCb: () =>
          history.push(`/projects/${project?.annotation_project_id}?asr=google_asr`),
        loading: false,
        submitBtnText: 'GOOGLE ASR',
        onSecondSubmitCb: () =>
          history.push(`/projects/${project?.annotation_project_id}?asr=aws_asr`),
        secondSubmitTxt: 'AWS ASR'
      });
    } else if (project.source_language_code) {
      if (
        project.status === 'draft' ||
        project.status === 'asr_processed' ||
        project.status === 'split_merge_processing'
      )
        history.push(`/projects/${project?.annotation_project_id}`);
      else if (
        project.status === 'split_merge_processed' ||
        project.status === 'edit_snippets_processing'
      )
        history.push(`/projects/${project?.annotation_project_id}/edit-snippets`);
      else if (
        project.status === 'edit_snippets_processed' ||
        project.status === 'mfa_processing' ||
        project.status === 'mfa_processed'
      )
        history.push(`/projects/${project?.annotation_project_id}/phoneme-verification`);
      else if (project.status === 'completed') {
        history.push(`/projects/${project?.annotation_project_id}/annotation-complete`);
      } else if (project.status === 'mfa_failed') {
        fetchProjectDetails(project.annotation_project_id)
          .unwrap()
          .then((value) => {
            dispatch(
              setStatus({
                type: 'error',
                message: value?.error_details?.message
              })
            );
            history.push(`/projects/${project?.annotation_project_id}/edit-snippets`);
          });
      }
    } else {
      dispatch(
        setProjectModal({
          showProjectModal: true,
          currentScreen: ScreenFlowTypes.UPLOAD,
          currentProject: project.annotation_project_id
        })
      );
    }
  };

  const handleAnnotationProjectDetails = (e) => {
    e.stopPropagation();
    fetchAnnnotationProjectDetails(project.annotation_project_id);
  };

  const router = useRouter();
  const { updateProjectStatus } = useProject();

  const handleMFAFailed = async (projectId) => {
    const data = await updateProjectStatus(projectId, PROJECT_STATUS.EDIT_SNIPPETS_PROCESSING);
    if (data) {
      router.push(`/projects/${projectId}/edit-snippets`);
    }
  };

  const handleGetStatus = (e) => {
    e.stopPropagation();
    fetchProjectDetails(project?.annotation_project_id)
      .unwrap()
      .then((value) => {
        if (value.status === 'mfa_failed') {
          dispatch(
            setStatus({
              type: 'error',
              message: value?.error_details?.message || 'MFA has failed !'
            })
          );
        }
      });
  };

  const handleOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleMenu(event.currentTarget);
  };

  const onSelectOption = (item: MenuType) => {
    setSelectedOption(item);
    toggleMenu(null);
  };

  const onProjectNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      onTitleRename();
    }
  };

  const onTitleRename = async () => {
    const response = await updateProjectName(annotation_project_id, currentProjectName);
    if (response) {
      setSelectedOption(MenuType.RENAME);
    }
  };

  const onProjectNameBlur = () => {
    onTitleRename();
  };

  const onTextFieldClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };
  return (
    <>
      <div
        className={styles.cardContainer}
        onClick={handleCardClick}
        onKeyPress={() => null}
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
      >
        <div className="flex flex-col">
          <div className={styles.header}>
            {selectedOption === MenuType.RENAME ? (
              <TextField
                id="project-rename"
                inputRef={titleRef}
                autoComplete="off"
                defaultValue={currentProjectName}
                variant="outlined"
                onKeyDown={handleKeyPress}
                onChange={onProjectNameChange}
                onClick={onTextFieldClick}
                onBlur={onProjectNameBlur}
                InputProps={{
                  classes: {
                    notchedOutline: styles.textOutline,
                    root: styles.textRoot,
                    input: styles.textInput
                  }
                }}
              />
            ) : (
              <span className={styles.text}>{currentProjectName}</span>
            )}
            <PropertyControlledComponent controllerProperty={project.status === 'completed'}>
              <Tooltip title="Project Details">
                <IconButton onClick={handleAnnotationProjectDetails}>
                  <ListAlt fontSize="small" className="text-white" />
                </IconButton>
              </Tooltip>
            </PropertyControlledComponent>
            <Tooltip title="Get Status">
              <div className="flex">
                <IconButton onClick={handleGetStatus}>
                  <Refresh fontSize="small" className="text-white" />
                </IconButton>
              </div>
            </Tooltip>
            <div className="flex">
              <IconButton onClick={handleOptionsClick}>
                <MoreVert fontSize="small" className="text-white" />
              </IconButton>
            </div>
            <PropertyControlledComponent controllerProperty={project.status === 'mfa_failed'}>
              <Tooltip title="Go back">
                <div className="flex">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMFAFailed(project.annotation_project_id);
                    }}
                  >
                    <KeyboardBackspace fontSize="small" className="text-white" />
                  </IconButton>
                </div>
              </Tooltip>
            </PropertyControlledComponent>
          </div>
          <div className={`${styles.subText} mt-2 tracking-widest`}>
            <span style={{ fontWeight: 'bold' }}>Last Edit : </span>
            {moment(project.updated_at).format('DD MMM YYYY')}
          </div>
          <div className={`${styles.subText} mt-1 tracking-widest `}>
            <span style={{ fontWeight: 'bold' }}>Id : </span>
            <span>{_get(project, 'annotation_project_id')}</span>
          </div>
          <div className={`${styles.subText} mb-4 mt-1 tracking-widest`}>
            <span className="font-bold">Status</span> : {_get(project, 'status')}
          </div>
        </div>

        <div className="flex flex-col">
          <span className={`${styles.subText}     tracking-widest`}>
            <span style={{ fontWeight: 'bold' }}>Created : </span>
            {moment(project.created_at).format('DD MMM YYYY')}
          </span>
          <span className={`${styles.subText}     tracking-widest`}>
            <span style={{ fontWeight: 'bold' }}>Updated : </span>
            {moment(project.updated_at).format('DD MMM YYYY')}
          </span>
        </div>
      </div>
      <Popover
        anchorEl={isMenuOpen}
        open={Boolean(isMenuOpen)}
        onClose={() => toggleMenu(null)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        classes={{ root: styles.popoverRoot, paper: styles.popoverPaper }}
      >
        <div className={styles.popover}>
          <MenuItem onClick={() => onSelectOption(MenuType.RENAME)} className={styles.menuOptions}>
            Rename
          </MenuItem>
          <Tooltip
            title={
              project.status === PROJECT_STATUS.MFA_PROCESSED ||
              project.status === PROJECT_STATUS.COMPLETED
                ? ''
                : 'Project should be mfa_processed or completed'
            }
          >
            <div>
              <MenuItem
                onClick={handleDuplicateProject}
                className={styles.menuOptions}
                disabled={
                  !(
                    project.status === PROJECT_STATUS.MFA_PROCESSED ||
                    project.status === PROJECT_STATUS.COMPLETED
                  )
                }
              >
                Duplicate
              </MenuItem>
            </div>
          </Tooltip>

          <MenuItem className={styles.menuOptions}>
            <ConfirmationDialog
              dialogTitle="Do you want to delete this project ?"
              closeButtonText="No"
              isButtonRequired={false}
              options={[{ optionText: 'Yes', onSelect: handleDeleteClick }]}
              handleButtonText="Delete"
              handleButtonClassname={styles.Usr}
            />
          </MenuItem>
        </div>
      </Popover>
    </>
  );
};

export default ProjectCard;
