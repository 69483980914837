import { Backdrop, CircularProgress, Zoom } from '@mui/material';
import React from 'react';

import PropertyControlledComponent from '../PropertyControlledComponent';

import styles from './OverlayLoader.module.scss';

export default function SimpleBackdrop({ open }: { open: boolean }) {
  return (
    <Backdrop className={styles.backdrop} open={open}>
      <PropertyControlledComponent controllerProperty={open}>
        <Zoom in={open} timeout={1000}>
          <CircularProgress color="primary" />
        </Zoom>
      </PropertyControlledComponent>
    </Backdrop>
  );
}
