import { Status } from './Input.types';

export const TextColor = {
  [Status.NORMAL]: '#BEBDFF',
  [Status.ERROR]: '#EE1313',
  [Status.SUCCESS]: '#039700',
  [Status.FOCUS]: '#5034FF'
};
export const InputbackGround = {
  [Status.NORMAL]: 'transparant',
  [Status.ERROR]: '#FFD4D4',
  [Status.SUCCESS]: '#C7FEC6',
  [Status.FOCUS]: 'transparant'
};
